<template>
  <p class="clearfix mt-2">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT  © {{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        href="http://www.scanclick.co"
        target="_blank"
      >LavarClick </b-link>
      <span class="d-none d-sm-inline-block">Se reservan Todos los derechos Civiles, Contractuales y demas que Apliquen A las Empresas Titulares de la Cuenta</span>
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>
