<template>
  <div class="row">
  
    <b-navbar-nav class="nav" style="border-style: solid !important; border-width: 2px !important; padding: 5px !important;" v-if="menuDocumental.length > 0">
      <div style="margin-left: 60px !important; margin-top: 25px !important; position: absolute !important; background-color: #FFFFFF !important; font-weight: bold !important; font-size: 11px !important; color: #000000 !important;">Documental</div>
      <b-nav-item
        v-for="(bookmark, index) in menuDocumental"
        :id="`bookmark-${index}`"
        :key="index"
        :to="bookmark.route"
      >
        <feather-icon
          :icon="bookmark.icon"
          size="25"
                  class="text-primary"
        />
        <b-tooltip
          triggers="hover"
          :target="`bookmark-${index}`"
          :title="bookmark.title"
          :delay="{ show: 1000, hide: 50 }"
        />
      </b-nav-item>
      <b-nav-item-dropdown
        link-classes="bookmark-star"
        lazy
        @hidden="resetsearchQuery"
      >
        <!-- <feather-icon
          slot="button-content"
          icon="StarIcon"
          size="21"
          class="text-warning"
        /> -->
  
        <!-- Dropdown Content -->
        <li style="min-width:300px">
          <div class="p-1">
            <b-form-input
              id="boomark-search-input"
              v-model="searchQuery"
              placeholder="Explore Vuexy..."
              autofocus
            />
          </div>
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="search-list search-list-bookmark scroll-area"
            :class="{'show': filteredData.pages && filteredData.pages.length }"
            tagname="ul"
          >
  
            <b-dropdown-item
              v-for="(suggestion, index) in filteredData.pages || bookmarks"
              :key="index"
              class="suggestion-group-suggestion cursor-pointer"
              link-class="d-flex align-items-center"
              :to="suggestion.route"
              @mouseenter="currentSelected = index"
            >
              <feather-icon
                :icon="suggestion.icon"
                class="mr-75"
                size="18"
              />
              <span class="align-middle">{{ suggestion.title }}</span>
              <feather-icon
                icon="StarIcon"
                class="ml-auto"
                size="16"
                :class="{'text-warning': suggestion.isBookmarked}"
                @click.stop.prevent="toggleBookmarked(suggestion)"
              />
  
            </b-dropdown-item>
            <b-dropdown-item
              v-show="!(filteredData.pages && filteredData.pages.length) && searchQuery"
              disabled
            >
              No Results Found.
            </b-dropdown-item></vue-perfect-scrollbar>
        </li>
      </b-nav-item-dropdown>
  
    </b-navbar-nav>
      <div style="width: 10px !important;">
      </div>
  
    <b-navbar-nav class="nav" style="border-style: solid !important; border-width: 2px !important; padding: 5px !important;" v-if="menuTrainning.length > 0">
      <div style="margin-left: 40px !important; margin-top: 25px !important; position: absolute !important; background-color: #FFFFFF !important; font-weight: bold !important; font-size: 11px !important; color: #000000 !important;">Trainning & Formacion</div>
  
      <b-nav-item
        v-for="(bookmark, index) in menuTrainning"
        :id="`bookmark2-${index}`"
        :key="(10+index)"
        :to="bookmark.route"
      >
        <feather-icon
          :icon="bookmark.icon"
          size="25"
          class="text-success"
        />
        <b-tooltip
          triggers="hover"
          :target="`bookmark2-${index}`"
          :title="bookmark.title"
          :delay="{ show: 1000, hide: 50 }"
        />
      </b-nav-item>
  
      </b-navbar-nav>
          <div style="width: 10px !important;">
      </div>
  
      <b-navbar-nav class="nav" style="border-style: solid !important; border-width: 2px !important; padding: 5px !important;" v-if="menuEmployees.length > 0">
      <div style="margin-left: 20px !important; margin-top: 25px !important; position: absolute !important; background-color: #FFFFFF !important; font-weight: bold !important; font-size: 11px !important; color: #000000 !important;">PartnerCheck & Auditoria</div>
      <b-nav-item
        v-for="(bookmark, index) in menuEmployees"
        :id="`bookmark3-${index}`"
        :key="(20+index)"
        :to="bookmark.route"
      >
        <feather-icon
          :icon="bookmark.icon"
          size="25"
          class="text-warning"
        />
        <b-tooltip
          triggers="hover"
          :target="`bookmark3-${index}`"
          :title="bookmark.title"
          :delay="{ show: 1000, hide: 50 }"
        />
      </b-nav-item>
      </b-navbar-nav>
  
      <b-navbar-nav class="nav" style="border-style: solid !important; border-width: 2px !important; padding: 5px !important; width: 200px !important;" v-if="menuSmartContracts.length > 0">
      <div style="margin-left: 40px !important; margin-top: 25px !important; position: absolute !important; background-color: #FFFFFF !important; font-weight: bold !important; font-size: 11px !important; color: #000000 !important;">Contratacion</div>
  <b-nav-item
        v-for="(bookmark, index) in menuSmartContracts"
        :id="`bookmark4-${index}`"
        :key="(20+index)"
        :to="bookmark.route"
      >
        <feather-icon
          :icon="bookmark.icon"
          size="25"
          class="text-success"
        />
        <b-tooltip
          triggers="hover"
          :target="`bookmark4-${index}`"
          :title="bookmark.title"
          :delay="{ show: 1000, hide: 50 }"
        />
      </b-nav-item>
      </b-navbar-nav>
      
      <b-navbar-nav class="nav" style="border-style: solid !important; border-width: 2px !important; padding: 5px !important; width: 100% !important; z-index: 999 !important;">
      <div style="margin-left: 17% !important; margin-top: -18px !important; position: absolute !important; background-color: #FFFFFF !important; font-weight: bold !important; font-size: 11px !important; color: #000000 !important;">Menu Principal LavarClick Console</div>
  <b-nav-item
        v-for="(bookmark, index) in menuLVCustomer"
        :id="`bookmark5-${index}`"
        :key="(20+index)"
        :to="bookmark.route"
        style="font-weight: bold !important; font-size: 12px !important;"
      >
      <feather-icon
          :icon="bookmark.icon"
          size="23"
          class="text-primary" 
        />
      {{bookmark.title}}
        
        <b-tooltip
          triggers="hover"
          :target="`bookmark5-${index}`"
          :title="bookmark.title"
          :delay="{ show: 1000, hide: 50 }"
        />
      </b-nav-item>
      </b-navbar-nav>
    </div>
  </template>
  
  <script>
  import {
    BNavbarNav, BNavItem, BTooltip, BNavItemDropdown, BFormInput, BDropdownItem,
  } from 'bootstrap-vue'
  import VuePerfectScrollbar from 'vue-perfect-scrollbar'
  import useAutoSuggest from '@core/components/app-auto-suggest/useAutoSuggest'
  import { ref, watch } from '@vue/composition-api'
  import router from '@/router'
  import store from '@/store'
  import searchAndBookmarkData from './search-and-bookmark-data'
  
  export default {
    components: {
      BNavbarNav, BNavItem, BTooltip, BNavItemDropdown, BFormInput, VuePerfectScrollbar, BDropdownItem,
    },
    setup() {
      const searchAndBookmarkDataPages = ref(searchAndBookmarkData.pages)
      const bookmarks = ref(searchAndBookmarkData.pages.data.filter(page => page.isBookmarked))
      const currentSelected = ref(-1)
      const menuDocumental = [
          // { title: 'Mis Documentos', route: { name: 'dashboard-ecommerce' }, icon: 'FileIcon', isBookmarked: true },
          // { title: 'Repositorios', route: { name: 'table-good-table' }, icon: 'PackageIcon', isBookmarked: true },
          // { title: 'Seguimiento', route: { name: 'apps-todo' }, icon: 'CheckSquareIcon', isBookmarked: true },
          // { title: 'Notificaciones', route: { name: 'apps-email' }, icon: 'MailIcon', isBookmarked: true },
          // { title: 'Mis Empleados', route: { name: 'apps-users-edit' }, icon: 'UsersIcon', isBookmarked: true }
      ];
      const menuTrainning = [
        // { title: 'Entrenamiento', route: { name: 'apps-users-view' }, icon: 'AwardIcon', isBookmarked: true },
        // { title: 'Crear Entrenamiento', route: { name: 'form-wizard' }, icon: 'PlusCircleIcon', isBookmarked: true },
        // { title: 'Mis Empleados', route: { name: 'apps-users-edit' }, icon: 'UsersIcon', isBookmarked: true },
        // { title: 'Realizar Entrenamiento', route: { name: 'trainning-execution' }, icon: 'EditIcon', isBookmarked: true },
        // { title: 'Empleado', route: { name: 'user-trainning-view' }, icon: 'HomeIcon', isBookmarked: true },
        // { title: 'Entrenamientos', route: { name: 'form-wizard' }, icon: 'LayersIcon', isBookmarked: true },
        // { title: 'Bienestar', route: { name: 'wellness-dashboard' }, icon: 'LayersIcon', isBookmarked: true },
        // { title: 'Gamify', route: { name: 'game-game' }, icon: 'LayersIcon', isBookmarked: true },
        // { title: 'ScanClickWorld', route: { name: 'game-world' }, icon: 'LayersIcon', isBookmarked: true },
      ];
      const menuEmployees = [
        
      // { title: 'Dashboard', route: { name: 'partnercheck-dashboard' }, icon: 'BookmarkIcon', isBookmarked: true },
      // { title: 'Configuracion', route: { name: 'form-layout' }, icon: 'BookmarkIcon', isBookmarked: true },
      // { title: 'Crear Partner', route: { name: 'new-partner' }, icon: 'UserIcon', isBookmarked: true },
      // { title: 'Crear PartnerCheck', route: { name: 'new-partnercheck' }, icon: 'ActivityIcon', isBookmarked: true },
      // { title: 'Crear Partner', route: { name: 'new-partner' }, icon: 'UsersIcon', isBookmarked: true }
      ];
  
      const menuSmartContracts = [
        
      // { title: 'Nuevo SmartContract', route: { name: 'new-smart-contract' }, icon: 'BookmarkIcon', isBookmarked: true }
    
      ];
  
      const menuLVCustomer = [
      { title: 'Dashboard', route: { name: 'admin-dashboard' }, icon: 'HomeIcon', isBookmarked: true },        
      { title: 'Categorias', route: { name: 'categories-dashboard' }, icon: 'LayersIcon', isBookmarked: true },
      { title: 'Usuarios', route: { name: 'menu-LV-Customer' }, icon: 'UsersIcon', isBookmarked: true },
      { title: 'Facturacion', route: { name: 'lv-invoice-view' }, icon: 'CheckCircleIcon', isBookmarked: true },
      { title: 'Ordenes', route: { name: 'lv-orders-dashboard' }, icon: 'BoxIcon', isBookmarked: true },
      { title: 'Pagos', route: { name: 'lv-payments-dashboard' }, icon: 'DollarSignIcon', isBookmarked: true },
      { title: 'Tickets', route: { name: 'lv-tickets-view' }, icon: 'PhoneIncomingIcon', isBookmarked: true },
      { title: 'Nueva Solicitud', route: { name: 'service-checkout' }, icon: 'PlusCircleIcon', isBookmarked: true },
              // { title: 'ScanClickWorld', route: { name: 'game-world' }, icon: 'LayersIcon', isBookmarked: true },
      ];
      
      const perfectScrollbarSettings = {
        maxScrollbarLength: 60,
      }
  
      const {
        searchQuery,
        resetsearchQuery,
        filteredData,
      } = useAutoSuggest({ data: { pages: searchAndBookmarkDataPages.value }, searchLimit: 6 })
  
      watch(searchQuery, val => {
        store.commit('app/TOGGLE_OVERLAY', Boolean(val))
      })
  
      watch(filteredData, val => {
        currentSelected.value = val.pages && !val.pages.length ? -1 : 0
      })
  
      const suggestionSelected = () => {
        const suggestion = filteredData.value.pages[currentSelected.value]
        router.push(suggestion.route).catch(() => {})
        resetsearchQuery()
      }
  
      const toggleBookmarked = item => {
        // Find Index of item/page in bookmarks' array
        const pageIndexInBookmarks = bookmarks.value.findIndex(i => i.route === item.route)
  
        // If index is > -1 => Item is bookmarked => Remove item from bookmarks array using index
        // Else => Item is not bookmarked => Add item to bookmarks' array
        if (pageIndexInBookmarks > -1) {
          bookmarks.value[pageIndexInBookmarks].isBookmarked = false
          bookmarks.value.splice(pageIndexInBookmarks, 1)
        } else {
          bookmarks.value.push(item)
          bookmarks.value[bookmarks.value.length - 1].isBookmarked = true
        }
      }
  
      return {
        bookmarks,
        perfectScrollbarSettings,
        currentSelected,
        suggestionSelected,
        toggleBookmarked,
  
        // AutoSuggest
        searchQuery,
        resetsearchQuery,
        filteredData,
        menuDocumental,
        menuTrainning,
        menuEmployees,
        menuSmartContracts,
        menuLVCustomer
      }
    },
  }
  </script>
  
  <style lang="scss" scoped>
  @import '~@core/scss/base/bootstrap-extended/include';
  
  ul
  {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  p {
    margin: 0;
  }
  
  .nav-bookmar-content-overlay {
      position: fixed;
      opacity: 0;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      -webkit-transition: all 0.7s;
      transition: all 0.7s;
      z-index: -1;
  
      &:not(.show) {
        pointer-events: none;
      }
  
      &.show {
        cursor: pointer;
        z-index: 10;
        opacity: 1;
      }
  }
  </style>
  